class BridgeMessagingAPI {
  static subscribe(callback) {
    window.addEventListener('message', callback, true);
    return () => window.removeEventListener('message', callback);
  }

  static sendEventToReactNative({ eventName, params }) {
    try {
      if (!window.ReactNativeWebView?.postMessage) {
        return null;
      }
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ eventName, params })
      );
    } catch (error) {
      console.log(error);
      throw new Error(`Something went wrong during ${eventName} execution`);
    }
  }

  static handshake = () => {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: 'handshake',
      params: {}
    });
  };

  static closeWebview = () => {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: 'closeWebview',
      params: {}
    });
  };

  static setNoTopInsetRoutes(routes) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: 'setNoTopInsetRoutes',
      params: { noTopInsetRoutes: routes }
    });
  }

  static setOriginBrowserList(origins) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: 'setOriginBrowserList',
      params: { originBrowserList: origins }
    });
  }

  static openUrl(url) {
    BridgeMessagingAPI.sendEventToReactNative({
      eventName: 'openUrl',
      params: { url }
    });
  }
}

export default BridgeMessagingAPI;
