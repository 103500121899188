export default {
  city_id: null,
  category_id: null,
  leased: null,
  room_ids: ['1', '2', '3', '5', '7'],
  price_from: null,
  price_to: null,
  area_from: null,
  area_to: null,
  paid_daily: ['true'],
  land_area: ['5', '6'],
  has_bill_of_sale: ['false'],
  has_mortgage: ['false'],
  has_repair: ['1', '2', '3', '4', '5', '6', '7', '10'],
  floors: ['1', '2', '3'],
  building_type: ['7'],
  location_ids: null,
  sorting: null
};
