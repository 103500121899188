import { gql } from '@apollo/client';
import genericFields from './genericFields';

const itemsFragmentQuery = gql`
  fragment itemFields on ESItem {
    ${genericFields}
    photos(limit: 4) {
      thumbnail
      f460x345
      large
    }
  }
`;

export default itemsFragmentQuery;
