import { Observable } from '@apollo/client/utilities';
import cookie from 'cookie';
import { hostname } from './url';
import config from '../../config';
import Bugsnag from '../bugsnag';

export const getNewToken = lang => {
  const headerParam = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  };

  return fetch(
    `${config.protocol}://${hostname(lang, config.host)}/api/session/new`,
    headerParam
  )
    .then(data => data.json())
    .then(responseData => responseData)
    .catch(error => Bugsnag.notify(error));
};

export const promiseToObservable = promise =>
  new Observable(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    );
  });

export const refreshToken = i18n => {
  return getNewToken(i18n.language).then(response => {
    document.cookie = `csrf_token=${response.csrf_token}`;
  });
};

export const getTokenFromCookies = () =>
  cookie.parse(document.cookie || '').csrf_token;

export const setHeadersWithToken = () => {
  return {
    headers: {
      'X-CSRF-Token': process.browser
        ? getTokenFromCookies()
        : config.fallback_token
    }
  };
};
